import React from 'react'
import { Link, navigate } from 'gatsby'
import { SearchProvider } from '../../context/search-provider'
import { StoreContext } from "../../context/store-context"
import { useMutation } from 'urql'
import Alert from '../../components/utils/Alert'
import { Layout } from "../../components/layout/layout"

const CreateToken = `
mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
    customerAccessTokenCreate(input: $input) {
        customerAccessToken {
            accessToken
            expiresAt
        }
        customerUserErrors {
            code
            field
            message
        }
    }
}`

function LoginPage() {
    const [loading, setLoading] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [alert, setAlert] = React.useState(undefined)

    const { token, storeToken } = React.useContext(StoreContext)

    const [createTokenResult, createToken] = useMutation(CreateToken)

    const login = async (e) => {
        e.preventDefault()
        setAlert(undefined)
        try {
            if (email === '' || password === '') return

            setLoading(true)
            const user = {
                email: email,
                password: password
            }
            const variables = { input: user }
            const { data: { customerAccessTokenCreate } } = await createToken(variables)

            if (customerAccessTokenCreate.customerUserErrors.length) {
                const [firstError] = customerAccessTokenCreate.customerUserErrors
                throw new Error(firstError.message)
            }
            else {
                setLoading(false)
                storeToken(customerAccessTokenCreate?.customerAccessToken)
            }
        } catch (error) {
            setLoading(false)
            console.error(error)
            setAlert({
                type: 'error',
                message: 'Email or password does not match',
                link: '',
                linkText: ''
            })
        }
    }

    React.useEffect(() => {
        if (token?.accessToken) navigate('/account')
    }, [token])
    
    return (
        <Layout>
            <div className="hero w-full bg-primary text-white mx-auto flex flex-col sm:flex-col items-center justify-center py-20 px-5 relative">
                <div className="text-4xl font-bold w-full text-center">
                    <Link to="/account/login/" title="Login">
                        <h1 className="leading-tight font-normal text-45xl">Login</h1>
                    </Link>
                </div>
            </div>

            { alert && <div className='pt-4 px-4'><Alert type={alert.type} msg={alert.message} link={alert.link} linkText={alert.linkText} /></div> }

            <div className="form-body flex flex-col mb-4 w-full mx-auto py-4 px-4 max-w-2xl relative">
                {
                    loading && (
                        <div className="absolute bg-background-overlay left-0 top-0 w-full h-full flex items-center justify-center z-50">
                            <svg className="animate-spin h-6 w-6 bg-transparent border-2 border-gray-sm border-t-transparent rounded-full" viewBox="0 0 24 24"></svg>
                        </div>
                    )
                }

                <form action="" method="post" onSubmit={e => login(e)}>
                    <div className="form-group mb-4">
                        <label htmlFor="email" className="block mb-1 uppercase">Email</label>
                        <input
                            type="text" 
                            name="email" 
                            id="email" 
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal" 
                            required
                            onChange={e => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mb-4">
                        <label htmlFor="password" className="block mb-1 uppercase">Password</label>
                        <input
                            type="password" 
                            name="password" 
                            id="password" 
                            className="bg-white focus:outline-none focus:shadow-outline border border-gray-400 focus:border-gray-600 py-4 px-6 block w-full appearance-none leading-normal"
                            required
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>

                    <div className="button-group mt-4">
                        <Link to="/account/reset/" className="block text-center sm:text-left text-primary underline mb-4 font-normal">Forgot your password?</Link>
                        <button
                            type="submit"
                            className="button w-full sm:w-auto items-center justify-center bg-primary border border-primary text-white py-4 px-8 uppercase text-sm tracking-widest font-bold mb-4">
                            <span>Sign In</span>
                        </button>
                        <Link to="/account/register/" className="block text-center sm:text-left text-primary underline mb-4 font-normal">New customer? Sign up for an account</Link>
                        <Link to="/" className="block text-center sm:text-left text-primary underline mb-4 font-normal">Return to Store</Link>
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default function LoginPageTemplate(props) {
    return (
        <SearchProvider>
            <LoginPage {...props} />
        </SearchProvider>
    )
}